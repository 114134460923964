(function(){

	var module = 'posts';

	var $window = $(document);
	var $body = $('html, body');
	var $categories_items = $('#categories');
	var $item = $categories_items.find('li');

	App.events.suscribe(module + 'Obtained', _render);
	App.events.suscribe(module + 'Pagination', _renderPagination);

	setCategories($categories_items);
	setPagination();

	function setCategories($filter){
		$item.on('click', $filter, function(event){
			event.preventDefault();
			removeURLParameter();
			var category_slug = $(this).find('a').attr('data-slug');
			setQueryStringParam('category', category_slug);

			App.makeAjaxCall(APP_CONFIG.SERVICE_URL + 'api/news?category=' + category_slug, 'GET', undefined, function(response){
				App.events.fire(module + 'Obtained', response);
				App.events.fire(module + 'Pagination', response);
			});
		});		
	};

	function setPagination(){
		$window.on('click', '.pagination a', function(event){
			event.preventDefault();
			var page = $(this).attr('href').split('page=')[1];
			var end_point = $(this).attr('href').split('page=')[0].split('/')[3];
			setQueryStringParam('page', page);
			getPostsByPagination(page, end_point);
		});
	};

	function _render(data) {
		var $source = $('#box-news-render').html();
		var $container = $('#js-hb');
		var template = Handlebars.compile($source);
		$container.empty();
		$container.html(template(data));
		scrollTop($container);
	};

	function _renderPagination(data){
		var $source = $('#paginator').html();
		var $container = $('.paginador-nav');
		var template = Handlebars.compile($source);
		$container.empty();
		$container.html(template(data));
	};

	function getPostsByPagination(page, url){
		App.makeAjaxCall(APP_CONFIG.SERVICE_URL + 'api/' + url + 'page=' + page, 'GET', undefined, function(response){
			App.events.fire(module + 'Obtained', response);
			App.events.fire(module + 'Pagination', response);
		});
	};

	function scrollTop(element){
		$body.animate({
	        scrollTop: element.offset().top
	    }, 1000);
	};

})();